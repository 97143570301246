// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://staging-api.ggboost.com',
  stripeKey: 'pk_test_aBc43KuaxuW2oEmrSyCjFAiu',
  amazonClientId: 'amzn1.application-oa2-client.7d2622d389794f09bdbfe28300b0576d',
  amazonSellerId: 'AQ6LOIRTXHYML',
  useAmazonSandbox: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
